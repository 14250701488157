.catalog-pager__wrap{
  max-width:100%;
  margin-top:90px
}

.catalog-pager__button-more{
  display:block;
  margin-bottom:30px;
  padding:16px 0;
  font-size:13px;
  border:1px solid #00aafa;
  border-radius:25px;
  color:#00aafa;
  background-color:transparent;
  text-align:center;
  text-transform:uppercase;
  transition:.25s ease;
  line-height:1.1;
  height:auto;
  max-width:350px;
  cursor:pointer;
  margin-right:auto;
  margin-left:auto
}

.catalog-pager__button-more:hover{
  color:#fff;
  border:1px solid #00aafa;
  background-color:#00aafa
}

.catalog-pager__button-more:focus{
  color:#f51391
}

.catalog-pager__button-more.disabled{
  pointer-events:none;
  border:1px solid #acacac;
  color:#acacac
}

.catalog-pager__list{
  margin:0;
  padding:0;
  align-items:center;
  justify-content:center;
  list-style:none
}

.section .text-justify .block.block-block .content .items-list.category-products .catalog-pager__wrap ul{
  text-align:center !important
}

.catalog-pager__item{
  display:inline-block;
  width:50px;
  height:50px;
  padding:0;
  margin:0 7px 10px
}

.catalog-pager__item:before{
  content:none
}

.catalog-pager__item.selected .catalog-pager__item-link,.catalog-pager__item:hover .catalog-pager__item-link{
  background-color:#00aafa;
  color:#fff
}

.catalog-pager__item .catalog-pager__item-link{
  display:inline-block;
  padding:0;
  margin:0;
  border:1px solid #00aafa;
  border-radius:50%;
  font-size:14px;
  color:#00aafa;
  transition:.25s ease;
  cursor:pointer
}

.catalog-pager__item.selected a{
  pointer-events:none
}

.catalog-pager__item.disabled{
  display:none
}

.catalog-pager__item:focus .catalog-pager__item-link{
  color:#f51391;
  text-decoration:underline
}

.catalog-pager__item.more-pages{
  pointer-events:none
}

.catalog-pager__item.previous-page .catalog-pager__item-link:before{
  position:absolute;
  transform:translate(-50%, -50%) rotate(-180deg);
  top:50%;
  left:50%;
  content:"";
  display:inherit;
  width:100%;
  height:14px;
  background:url(/var/resources/static/images/arrow-icon3165.svg) center center no-repeat
}

.catalog-pager__item.previous-page:hover .catalog-pager__item-link:before{
  filter:invert(100%) brightness(100)
}

.catalog-pager__item.next-page .catalog-pager__item-link:before{
  content:"";
  position:absolute;
  transform:translate(-50%, -50%);
  top:50%;
  left:50%;
  display:inherit;
  width:100%;
  height:14px;
  background:url(/var/resources/static/images/arrow-icon3165.svg) center center no-repeat
}

.catalog-pager__item.next-page:hover .catalog-pager__item-link::before{
  filter:invert(100%) brightness(100)
}

.catalog-pager__item-link{
  border:none;
  line-height:3.5;
  width:100%;
  height:100%;
  text-align:center
}

@media screen and (max-width: 640px){
  .catalog-pager__item{
    margin:0 2px 10px
  }
}

.catalog-pager__wrap .pagers__list,.catalog-pager__wrap .catalog-pager__list{
  text-align:center !important
}