.main-sprite__before.layout-icon__left-arrow:before,.main-sprite__before.layout-icon__right-arrow:before{
  margin-left:-10px;
  height:20px;
  width:22px
}

.main-sprite__before.layout-icon__left-arrow:before{
  background-position-y:-1027px
}

.main-sprite__before.layout-icon__right-arrow:before{
  background-position-y:-1049px
}

.main-sprite__before.layout-icon__page-footer:before{
  background-position-y:0;
  background-position-x:-31px;
  width:48px;
  height:48px;
  margin:0;
  top:0
}