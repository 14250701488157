.checkout-final{
  max-width:900px;
  width:auto;
  margin:0 auto
}

.checkout-final h1{
  text-align:center;
  margin-bottom:50px
}

.checkout-final__info{
  padding:0;
  display:grid;
  grid-template-columns:1fr 1fr;
  align-items:center
}

.checkout-final__order-number{
  grid-column:1;
  font-size:20px;
  line-height:1;
  color:#00a9fa
}

.checkout-final__order-date{
  grid-column:2;
  text-align:right;
  font-size:14px;
  color:#868686;
  line-height:1
}

.order-table__head{
  background-color:#f2f2f2;
  padding:18px 0
}

.order-table__head-column{
  font-size:14px;
  line-height:1;
  color:#373737
}

.order-table__item{
  padding:15px 0;
  margin-bottom:1px;
  background-color:#fff;
  color:#252525
}

.order-table__item-column{
  color:#252525;
  font-size:13px;
  font-weight:300
}

.order-table__item-title{
  margin-bottom:4px;
  font-size:13px;
  line-height:1.1
}

.order-table__item-title:last-child{
  margin-bottom:0
}

.order-table__footer-title,.order-table__footer-total{
  color:#00a9fa
}

.product-params{
  color:#252525;
  list-style:none;
  padding:0
}

.product-table__item-option,.product-params__item{
  font-size:13px
}

.product-params__item{
  padding:0;
  margin-bottom:2px;
  font-size:13px
}

.product-params__item:before{
  content:none
}

.product-params__item:last-child{
  margin-bottom:0
}

.checkout-final__actions{
  width:auto;
  max-width:550px;
  flex-wrap:wrap;
  justify-content:space-between;
  align-items:center
}

.checkout-final__action-print{
  box-sizing:border-box;
  width:255px;
  height:50px;
  text-align:center;
  text-decoration:none;
  border:1px solid #00a9fa;
  border-radius:25px;
  background-color:#00a9fa;
  transition:.3s
}

.checkout-final__action-print span{
  font-size:13px;
  font-weight:300;
  line-height:50px;
  text-transform:uppercase;
  color:#fff;
  transition:.3s
}

.checkout-final__action-print span:before{
  display:inline-block;
  vertical-align:middle;
  content:"";
  width:24px;
  height:22px;
  background-image:url(/var/resources/static/images/printfba3.svg);
  background-repeat:no-repeat;
  background-size:cover;
  margin-right:10px;
  margin-top:-3px;
  transition:.3s
}

.checkout-final__action-print:hover{
  border-bottom:1px solid #00a9fa;
  background-color:#fff
}

.checkout-final__action-print:hover span{
  color:#00a9fa
}

.checkout-final__action-print:hover span:before{
  background-image:url(/var/resources/static/images/print-hovere9c0.svg)
}

.checkout-final__action-pdf{
  box-sizing:border-box;
  width:255px;
  height:50px;
  text-align:center;
  text-decoration:none;
  border:1px solid #00a9fa;
  border-radius:25px;
  background-color:#00a9fa;
  transition:.3s
}

.checkout-final__action-pdf span{
  font-size:13px;
  font-weight:300;
  line-height:50px;
  text-transform:uppercase;
  color:#fff;
  transition:.3s
}

.checkout-final__action-pdf span:before{
  display:inline-block;
  vertical-align:middle;
  content:"";
  width:19px;
  height:24px;
  background-image:url(/var/resources/static/images/pdf7e9b.svg);
  background-repeat:no-repeat;
  background-size:cover;
  margin-right:10px;
  margin-top:-5px;
  transition:.3s
}

.checkout-final__action-pdf:hover{
  border-bottom:1px solid #00a9fa;
  background-color:#fff
}

.checkout-final__action-pdf:hover span{
  color:#00a9fa
}

.checkout-final__action-pdf:hover span:before{
  background-image:url(/var/resources/static/images/pdf-hovere242.svg)
}

@media screen and (min-width: 992px){
  .order-table{
    margin-bottom:30px
  }

  .order-table__head,.order-table__item{
    display:grid;
    grid-template-columns:376px 30px 106px 30px 106px 30px 106px 30px 80px
  }

  .order-table__head-column:first-child,.order-table__item-column:first-child{
    grid-column:1;
    padding-left:30px
  }

  .order-table__head-column:nth-child(2),.order-table__item-column:nth-child(2){
    grid-column:3
  }

  .order-table__head-column:nth-child(3),.order-table__item-column:nth-child(3){
    grid-column:5
  }

  .order-table__head-column:nth-child(4),.order-table__item-column:nth-child(4){
    grid-column:7
  }

  .order-table__head-column:nth-child(5),.order-table__item-column:nth-child(5){
    grid-column:9;
    padding-right:22px
  }

  .products-table__body{
    padding:0 4px;
    background-color:#f2f2f2
  }

  .order-table__footer{
    display:grid;
    grid-template-columns:1fr 1fr;
    padding:16px 22px 16px 30px;
    background-color:#f2f2f2;
    font-size:13px;
    color:#00a9fa
  }

  .order-table__footer-title{
    grid-column:1
  }

  .order-table__footer-total{
    grid-column:2;
    text-align:right
  }

  .order-table__item-name,.product-table__item-option{
    padding-left:75px
  }

  .order-table__item-name{
    font-size:14px;
    line-height:1.3;
    color:#000
  }

  .order-table__item-head{
    display:none
  }

  .order-table__item-count,.order-table__item-price{
    font-weight:700
  }

  .order-table__item-count{
    text-align:center
  }

  .order-table__item-total{
    color:#00a9fa
  }

  .order-table__delivery-price{
    text-align:right
  }
}

@media screen and (max-width: 991px){
  .checkout-final__info{
    grid-template-columns:2fr 1fr
  }

  .order-table{
    margin-bottom:10px
  }

  .products-table__body{
    background-color:transparent
  }

  .order-table__item-column{
    display:grid;
    grid-template-columns:1fr 2fr;
    align-items:center
  }

  .order-table__item-column:last-child .order-table__item-head{
    border-color:#f2f2f2
  }

  .order-table__item{
    margin-bottom:10px;
    padding:0;
    border-top:1px solid #f2f2f2
  }

  .order-table__item:last-child{
    margin-bottom:0
  }

  .order-table__item-head{
    grid-column:1;
    display:flex;
    align-items:center;
    height:50px;
    margin-bottom:0;
    padding:0 14px;
    border-bottom:1px solid #fff;
    background-color:#f2f2f2
  }

  .order-table__item-title{
    grid-column:2;
    display:flex;
    height:100%;
    align-items:center;
    margin-bottom:0;
    padding:16px 14px;
    font-weight:300;
    color:#252525;
    border-bottom:1px solid #f2f2f2
  }

  .order-table__item-name{
    font-size:13px
  }

  .order-table__footer{
    display:grid;
    grid-template-columns:1fr 2fr;
    height:30px
  }

  .order-table__footer-title{
    grid-column:1;
    display:flex;
    height:100%;
    align-items:center;
    margin-bottom:0;
    padding:0 14px
  }

  .order-table__footer-total{
    grid-column:2;
    display:flex;
    height:100%;
    align-items:center;
    margin-bottom:0;
    padding:0 14px;
    color:#00a9fa
  }

  .checkout-final__action-pdf{
    margin:auto
  }

  .order-table__item-column--mobile-hidden,.product-table__item-option,.order-table__head,.btn--mobile-hidden{
    display:none
  }
}