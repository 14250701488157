.v-product-popup__header{
  display:flex;
  flex-wrap:nowrap;
  flex-direction:column;
  justify-content:space-between;
  font-weight:700;
  font-size:16px;
  line-height:17px;
  text-align:left;
  margin-bottom:20px
}
@media (min-width: 640px){
.v-product-popup__header{
    font-size:22px;
    line-height:32px;
    flex-direction:row
}
}
@media screen and (orientation: landscape)and (max-device-width: 900px){
.v-product-popup__header{
    font-size:16px;
    line-height:17px
}
}
.v-product-popup__spinner{
  margin:40px
}
.v-product-popup--title{
  flex:1;
  color:#000;
  text-transform:uppercase;
  font-size:22px;
  line-height:32px;
  margin-bottom:0;
  text-align:center
}
@media (min-width: 640px){
.v-product-popup--title{
    text-align:left
}
}
.v-product-popup--price{
  padding-left:15px;
  color:#00aafa;
  text-align:center;
  font-size:28px;
  font-weight:700
}
@media (min-width: 640px){
.v-product-popup--price{
    text-align:left;
    font-size:22px
}
}
.v-product-popup__body{
  display:flex;
  flex-direction:column;
  flex-wrap:nowrap;
  grid-gap:28px;
  gap:28px
}
@media (min-width: 640px){
.v-product-popup__body{
    flex-direction:row;
    grid-gap:54px;
    gap:54px
}
}
.v-product-popup--image{
  height:auto;
  max-height:650px;
  box-sizing:content-box
}
@media (min-width: 640px){
.v-product-popup--image{
    max-width:160px;
    max-height:100px;
    margin:16px 0 30px
}
}
@media screen and (orientation: landscape)and (max-device-width: 900px){
.v-product-popup--image{
    max-width:auto;
    max-height:auto;
    border:none
}
}
.v-product-popup--attributes{
  display:flex;
  flex-wrap:wrap;
  flex-direction:column;
  justify-content:flex-start;
  grid-row-gap:23px;
  row-gap:23px;
  margin-bottom:54px
}
@media (min-width: 640px){
.v-product-popup--attributes{
    flex-direction:row;
    grid-row-gap:8px;
    row-gap:8px;
    grid-column-gap:30px;
    column-gap:30px;
    margin-bottom:30px
}
}
@media screen and (orientation: landscape)and (max-device-width: 900px){
.v-product-popup--attributes{
    flex-wrap:wrap
}
}
.v-product-popup--attribute{
  display:flex;
  align-items:center;
  text-align:left
}
@media (min-width: 640px){
.v-product-popup--attribute{
    flex-direction:column;
    align-items:flex-start
}
}
.v-product-popup--attribute-name{
  font-size:12px;
  line-height:12px;
  color:#000;
  text-transform:uppercase;
  margin-bottom:6px;
  flex:0 0 80px
}
@media (min-width: 640px){
.v-product-popup--attribute-name{
    flex:0 0 auto
}
}
.v-product-popup--attribute-input{
  position:relative;
  height:36px;
  font-size:18px;
  padding:0;
  font-weight:400;
  width:180px;
  min-width:115px;
  outline:none;
  transition:background .3s;
  border-radius:25px;
  padding:0 10px;
  -webkit-appearance:none;
          appearance:none;
  background-image:url("/frontend-new/assets/icons/select-arrow.svg");
  background-repeat:no-repeat;
  background-position:calc(100% - 10px) center;
  background-color:#f2f5f7;
  border:1px solid #f3f3f3;
  flex:1 1 auto
}
@media (min-width: 640px){
.v-product-popup--attribute-input{
    flex:0 0 auto
}
}
.v-product-popup--attribute-input.error{
  border-color:#df1b1b;
  background-color:#fad6d6
}
.v-product-popup--amount{
  margin-bottom:0;
  text-align:left
}
.v-product-popup--amount input[type=number]{
  height:27px;
  border:1px solid #e1e1e1;
  box-shadow:inset 0 2px 3px rgba(0,0,0,.1);
  padding:0 3px
}
.v-product-popup--amount input[type=number]:focus{
  border:1px solid #00aafa
}
@media (min-width: 640px){
.v-product-popup--amount{
    padding:25px 18px
}
}
.v-product-popup--amount input[type=number]::-webkit-outer-spin-button,.v-product-popup--amount input[type=number]::-webkit-inner-spin-button{
  -webkit-appearance:none;
  margin:0
}
.v-product-popup--amount input[type=number]{
  -moz-appearance:textfield
}
.v-product-popup--amount input[type=number]:hover,.v-product-popup--amount input[type=number]:focus{
  -moz-appearance:number-input
}
@media (min-width: 640px){
.v-product-popup--amount{
    margin-bottom:0
}
}
.v-product-popup__footer button{
  width:100%;
  height:40px;
  border-radius:3px;
  background-color:#00aafa;
  font-size:14px;
  line-height:38px;
  text-transform:unset;
  padding:0;
  text-align:center;
  -webkit-appearance:none;
  -moz-appearance:none;
  border:1px solid #00aafa;
  color:#fff
}
.v-product-popup__footer button:hover{
  color:#00aafa;
  background-color:#fff
}
.v-product-popup__footer button:focus{
  outline:none
}
@media (min-width: 640px){
.v-product-popup__footer button{
    max-width:220px
}
}
@media screen and (orientation: landscape)and (max-device-width: 900px){
.v-product-popup__footer button{
    width:100%;
    max-width:auto
}
}
.v-product-popup__promo{
  flex:1 0 auto;
  display:flex;
  align-items:center;
  margin-bottom:30px;
  padding-top:10px
}
.v-product-popup__promo-img{
  width:100%
}
@media (min-width: 1280px){
.v-product-popup__promo{
    padding-top:0
}
}
.popup-body .v-product-popup--title{
  line-height:32px;
  margin-bottom:12px
}
@media (min-width: 640px){
.popup-body .v-product-popup--title{
    margin-bottom:0
}
}
.popup-body .v-product-popup__footer{
  font-size:14px
}
.product-add-to-cart.c-base.c-popup-overlay .c-popup{
  padding:38px 24px
}