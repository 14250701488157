.checkout-thanks-page-title{
  font-style:normal;
  font-weight:normal;
  font-size:32px;
  line-height:32px;
  color:#373737;
  margin-bottom:18px
}

.checkout-thanks-order-status{
  font-style:normal;
  font-weight:normal;
  font-size:18px;
  line-height:24px;
  text-transform:uppercase;
  color:#373737
}

.checkout-thanks-message-wrapper{
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
  flex-wrap:wrap;
  margin-top:-10px;
  margin-bottom:40px
}

.checkout-thanks-message-wrapper .checkout-back-button{
  margin-right:50px;
  margin-top:24px
}

.checkout-final{
  max-width:1040px !important
}

.checkout-thanks-message{
  font-style:normal;
  font-weight:normal;
  font-size:18px;
  line-height:24px;
  padding-top:24px;
  color:#5b6770
}

.checkout-thanks-message.personal-message{
  padding:0;
  margin:auto 0
}

.personal-link{
  margin-top:15px;
  border-bottom:unset
}

.checkout-back-button{
  font-style:normal;
  font-weight:normal;
  font-size:14px;
  line-height:20px;
  text-align:center;
  color:#fff !important;
  display:inline-block;
  padding:14px 32px;
  background:#fb1e9a;
  border-radius:24px
}

.checkout-thanks-products-date{
  font-style:normal;
  font-weight:normal;
  font-size:18px;
  line-height:24px;
  color:#373737;
  text-transform:uppercase;
  margin-bottom:18px
}

.thanks-product-wrapper{
  display:flex;
  flex-direction:row;
  align-items:stretch;
  flex:1;
  box-sizing:border-box;
  font-style:normal;
  font-weight:normal;
  font-size:14px;
  line-height:20px;
  border-radius:4px;
  color:#373737;
  background-color:#fff;
  padding:0;
  margin-bottom:8px;
  height:128px;
  position:relative
}

.thanks-product-wrapper.personal-message{
  height:auto;
  margin-bottom:40px
}

.thanks-product-image-wrapper{
  width:200px;
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  overflow:hidden;
  flex:0 0 auto
}

.thanks-product-image-wrapper.personal-message{
  height:200px
}

.thanks-product-image-wrapper.personal-message .thanks-product-image{
  max-height:85px
}

.thanks-product-image{
  height:calc(100% - 24px);
  max-height:102px
}

.thanks-product-info-wrapper{
  flex:1;
  display:flex;
  flex-direction:column
}

.thanks-product-info-wrapper.personal-message{
  padding:20px 15px
}

.thanks-product-top-wrapper{
  height:80px;
  border-bottom:1px solid #f2f2f2;
  display:flex;
  flex-direction:row;
  justify-content:space-between
}

.thanks-product-name-vendor-wrapper{
  position:relative;
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:center;
  padding-left:24px
}

.thanks-product-name{
  font-style:normal;
  font-weight:normal;
  font-size:14px;
  line-height:20px;
  color:#373737
}

.thanks-product-vendor{
  display:flex;
  align-items:center
}

.thanks-product-vendor p{
  font-style:normal;
  font-weight:normal;
  font-size:12px;
  line-height:22px;
  color:#d9dee1;
  margin-right:20px;
  margin-bottom:0px
}

.thanks-product-details-wrapper{
  display:flex;
  flex-direction:row;
  align-items:center;
  padding-right:28px;
  padding-top:22px;
  white-space:nowrap
}

.thanks-product-details-amount{
  font-style:normal;
  font-weight:normal;
  font-size:14px;
  line-height:20px;
  color:#373737
}

.thanks-product-details-price{
  font-style:normal;
  font-weight:normal;
  font-size:24px;
  line-height:24px;
  letter-spacing:-0.04em;
  padding-left:15px;
  color:#00a9fa
}

.thanks-product-details-price span{
  font-family:RUBSN
}

.thanks-product-bottom-wrapper{
  flex:1;
  display:flex;
  flex-direction:row;
  flex-wrap:wrap
}

.thanks-product-attribute-wrapper{
  overflow:hidden;
  padding:8px 22px 4px 22px;
  border-right:1px solid #f2f2f2;
  border-top:1px solid #f2f2f2;
  margin-top:-1px
}

.thanks-product-attribute-label{
  font-style:normal;
  font-weight:normal;
  font-size:12px;
  line-height:16px;
  color:#5b6770
}

.thanks-product-attribute-value{
  font-style:normal;
  font-weight:normal;
  font-size:12px;
  line-height:20px;
  color:#373737
}

.checkout-thanks-page-details-wrapper{
  display:flex;
  flex-direction:row;
  align-items:stretch;
  flex-wrap:wrap;
  margin:0 -8px
}

.checkout-thanks-order-details{
  display:flex;
  flex-direction:row;
  align-items:stretch;
  flex:1;
  border-radius:4px;
  background-color:#fff;
  padding:0;
  min-height:128px;
  margin:16px 8px 0
}

.checkout-thanks-details-col-wrapper{
  flex:1;
  padding-left:40px;
  padding-top:24px
}

.checkout-thanks-col-title{
  font-style:normal;
  font-weight:normal;
  font-size:18px;
  line-height:24px;
  text-transform:uppercase;
  color:#373737
}

.checkout-thanks-col-info{
  padding-top:8px;
  font-style:normal;
  font-weight:normal;
  font-size:12px;
  line-height:16px;
  color:#373737
}

.checkout-thanks-col-info-phone{
  white-space:nowrap
}

.checkout-thanks-pricing-block-wrapper{
  background-color:#00a9fa;
  border-radius:4px;
  min-width:212px;
  padding:24px 12px 20px 24px;
  color:#fff;
  margin:16px 8px 0
}

.checkout-thanks-pricing-block-summ{
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  font-style:normal;
  font-weight:normal;
  font-size:14px;
  line-height:20px;
  color:#fff
}

.checkout-thanks-pricing-block-discount{
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  font-style:normal;
  font-weight:normal;
  font-size:14px;
  line-height:20px;
  color:#fff;
  margin:10px 0
}

.checkout-thanks-pricing-block-delivery{
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  font-style:normal;
  font-weight:normal;
  font-size:14px;
  line-height:20px;
  margin-top:4px;
  color:#fff
}

.checkout-thanks-pricing-block-total{
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  font-style:normal;
  font-weight:normal;
  font-size:24px;
  line-height:24px;
  text-transform:uppercase;
  margin-top:16px;
  color:#fff
}

.checkout-thanks-pricing-block-value{
  display:flex;
  flex-direction:row;
  justify-content:flex-end;
  align-items:baseline;
  color:#fff;
  font-size:inherit;
  line-height:inherit;
  font-family:inherit
}

.checkout-thanks-pricing-block-num{
  margin-right:4px;
  color:#fff;
  font-size:inherit;
  line-height:inherit;
  font-family:inherit
}

.checkout-thanks-pricing-block-currency{
  width:20px;
  color:#fff;
  font-size:inherit;
  line-height:inherit;
  font-family:"RUBSN"
}

.checkout-thanks-second-wrapper{
  max-width:512px;
  margin:0 auto;
  text-align:center
}

.checkout-thanks-second{
  font-style:normal;
  font-weight:normal;
  font-size:32px;
  line-height:32px;
  color:#00a9fa;
  margin:56px 0 24px
}

.checkout-pricing-block-currency{
  color:#fff
}

.checkout-thanks__gift{
  display:flex;
  padding:30px 0;
  flex-wrap:wrap;
  align-items:center
}

.checkout-thanks__gift-heading{
  flex:0 0 100%;
  margin-bottom:15px;
  max-width:100%;
  font-size:18px;
  line-height:1.3;
  text-transform:uppercase
}

.checkout-thanks__gift-title{
  flex:1 auto;
  margin:0;
  color:#d9027d;
  font-size:20px
}

.checkout-thanks__gift-link{
  flex:0 auto;
  padding:14px 32px;
  margin-right:50px;
  background-color:#d9027d;
  color:#fff;
  border-bottom:none;
  border-radius:32px;
  font-size:14px;
  line-height:1
}

.checkout-thanks__gift-link:visited{
  color:#fff
}

.checkout-thanks__gift-link:hover{
  border:none
}

.multicard{
  background-color:#fff;
  border-radius:4px;
  margin-top:-6px;
  margin-bottom:8px;
  padding:18px 35px
}

.multicard__wrapper{
  display:flex;
  align-items:center
}

.multicard__text{
  font-size:16px;
  line-height:18px;
  margin-bottom:0;
  max-width:551px
}

.multicard__text--small{
  color:#868686;
  font-size:12px;
  margin-top:10px;
  max-width:none
}

.multicard__image{
  width:102px;
  margin-right:25px
}

@media screen and (max-width: 1249px){
  .checkout-thanks-page-details-wrapper{
    flex-direction:column-reverse
  }

  .checkout-thanks__gift-link{
    margin-right:0
  }

  .multicard{
    padding:18px 17px 12px
  }

  .multicard__image{
    width:83px;
    margin-right:15px
  }
}

@media screen and (max-width: 991px){
  .checkout-thanks-order-details{
    flex-direction:column;
    padding-bottom:16px
  }

  .checkout-thanks-details-col-wrapper{
    padding-left:12px;
    padding-right:12px;
    padding-bottom:12px
  }

  .checkout-thanks__gift-title{
    flex:0 0 100%
  }

  .checkout-thanks__gift-link{
    margin-top:20px
  }
}

@media screen and (max-width: 640px){
  .thanks-product-image-wrapper{
    position:absolute;
    right:0;
    height:calc(100% - 49px);
    background:#fff;
    width:104px
  }

  .thanks-product-image-wrapper.personal-message{
    position:relative
  }

  .thanks-product-image-wrapper.personal-message .thanks-product-image{
    max-height:102px
  }

  .thanks-product-details-wrapper{
    padding-right:12px
  }

  .thanks-product-details-wrapper{
    position:absolute;
    right:0;
    bottom:8px
  }

  .checkout-back-button{
    width:100%
  }

  .checkout-thanks-message-wrapper .checkout-back-button{
    margin-right:0
  }

  .checkout-thanks-second-wrapper .checkout-back-button{
    width:auto;
    display:block
  }

  .thanks-product-name-vendor-wrapper{
    width:calc(100% - 117px)
  }

  .multicard__text{
    font-size:14px;
    line-height:16px;
    max-width:none
  }

  .multicard__text--small{
    font-size:11px;
    line-height:14px;
    margin-top:20px
  }

  .multicard__image{
    width:56px
  }
}