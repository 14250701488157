@keyframes lds-spinner{
  0%{
    opacity:1
  }

  100%{
    opacity:0
  }
}

.lds-spinner{
  width:32px;
  height:32px;
  margin:0 auto
}

.lds-spinner div{
  transform-origin:16px 16px;
  animation:lds-spinner 1.2s linear infinite
}

.lds-spinner div:after{
  content:"";
  display:block;
  position:absolute;
  top:23px;
  left:15px;
  width:2.4px;
  height:9px;
  border-radius:2px;
  background:#9a9a9a
}

.lds-spinner div:nth-child(1){
  transform:rotate(0deg);
  animation-delay:-1.1s
}

.lds-spinner div:nth-child(2){
  transform:rotate(30deg);
  animation-delay:-1s
}

.lds-spinner div:nth-child(3){
  transform:rotate(60deg);
  animation-delay:-0.9s
}

.lds-spinner div:nth-child(4){
  transform:rotate(90deg);
  animation-delay:-0.8s
}

.lds-spinner div:nth-child(5){
  transform:rotate(120deg);
  animation-delay:-0.7s
}

.lds-spinner div:nth-child(6){
  transform:rotate(150deg);
  animation-delay:-0.6s
}

.lds-spinner div:nth-child(7){
  transform:rotate(180deg);
  animation-delay:-0.5s
}

.lds-spinner div:nth-child(8){
  transform:rotate(210deg);
  animation-delay:-0.4s
}

.lds-spinner div:nth-child(9){
  transform:rotate(240deg);
  animation-delay:-0.3s
}

.lds-spinner div:nth-child(10){
  transform:rotate(270deg);
  animation-delay:-0.2s
}

.lds-spinner div:nth-child(11){
  transform:rotate(300deg);
  animation-delay:-0.1s
}

.lds-spinner div:nth-child(12){
  transform:rotate(330deg);
  animation-delay:0s
}

.vue-pure-target html,.vue-pure-target body{
  height:100%;
  font-size:100%
}

.vue-pure-target html{
  line-height:1.5
}

.vue-pure-target body{
  min-height:100%;
  background-color:#fff;
  font-size:12px
}

.vue-pure-target h1{
  font-size:40px;
  line-height:48px;
  color:#000;
  margin-bottom:25px;
  text-transform:uppercase
}

.vue-pure-target h2{
  font-size:30px;
  color:#000;
  margin-bottom:15px;
  line-height:48px;
  text-transform:uppercase
}

.vue-pure-target h3{
  font-size:20px;
  line-height:30px;
  text-transform:uppercase
}

.vue-pure-target h4{
  color:#00aafa;
  font-size:16px;
  line-height:48px;
  text-transform:uppercase;
  margin-bottom:5px
}

.vue-pure-target p{
  line-height:2;
  color:#000;
  margin-bottom:1.5em
}

.vue-pure-target mark{
  font-size:16px;
  line-height:28px;
  background-color:#fc48a2;
  color:#fff;
  text-align:left;
  padding-left:10px
}

.vue-pure-target small{
  font-size:11px
}

.vue-pure-target blockquote{
  border-top:1px solid #e5e5e5;
  border-bottom:1px solid #e5e5e5;
  position:relative;
  font-size:26px;
  line-height:40px;
  padding:55px 0;
  font-style:italic;
  text-align:center;
  margin:30px 0
}

.vue-pure-target blockquote:before{
  width:71px;
  height:19px;
  content:"";
  position:absolute;
  top:-9px;
  left:50%;
  margin-left:-35px
}

.vue-pure-target thead{
  background-color:#00aafa;
  font-size:14px;
  line-height:25px
}

.vue-pure-target td{
  padding:0 5px
}

.vue-pure-target .icon{
  display:inline-block
}

.vue-pure-target .bordered{
  border-top:1px solid #ccc;
  border-bottom:1px solid #ccc
}

.vue-pure-target b,.vue-pure-target strong{
  font-weight:bold
}

.vue-pure-target input[type=submit],.vue-pure-target button,.vue-pure-target a.button{
  border:1px solid #00aafa;
  overflow:visible;
  white-space:nowrap;
  font-family:inherit;
  font-size:inherit;
  color:#fff;
  transition:color .2s ease,background-color .2s ease;
  -webkit-transition:color .2s ease,background-color .2s ease;
  -o-transition:color .2s ease,background-color .2s ease;
  -moz-transition:color .2s ease,background-color .2s ease;
  -ms-transition:color .2s ease,background-color .2s ease;
  outline:none
}

@media screen and (max-width: 640px){
  .vue-pure-target html,.vue-pure-target body{
    font-size:32.25%
  }
}

.vue-pure-target h1,.vue-pure-target h2,.vue-pure-target h3{
  text-transform:none
}

.vue-pure-target h1{
  margin:0px 0px 0px 100px;
  padding:43px 0px 0px 12px;
  font-size:36px;
  color:#595b5c;
  line-height:36px
}

.vue-pure-target h2{
  font-size:24px;
  margin:18px 0;
  line-height:36px
}

.vue-pure-target .page-wrapper{
  margin:auto;
  min-height:100%
}

.vue-pure-target .page-main__wrapper{
  width:100%;
  position:relative;
  margin-top:20px
}

.vue-pure-target .page-main__container{
  margin:0 auto;
  padding:0;
  position:relative
}

.vue-pure-target .page-main__content{
  width:100%;
  margin-left:0;
  float:none
}

.vue-pure-target .page-main__content h1,.vue-pure-target .page-main__content .h1{
  padding:0;
  margin:0;
  font-size:32px;
  line-height:40px;
  font-weight:700;
  color:#000;
  margin-bottom:25px
}

.vue-pure-target .page-main__sidebar~*,.vue-pure-target .page-main__sidebar+.page-main__content{
  width:72%;
  float:right;
  margin-right:1.6%
}

.vue-pure-target .product-main-header__heading h1{
  text-transform:uppercase !important
}

.vue-pure-target a#main-content{
  border-bottom:0 !important;
  display:block;
  height:0;
  width:100%
}

.vue-pure-target .page-main__wrapper.top-margin{
  margin-top:0
}

.vue-pure-target .grecaptcha-badge{
  z-index:1001;
  display:none !important
}

.vue-pure-target .itech-link{
  border-bottom:0 !important;
  padding-left:30px;
  color:#9b9b9b;
  font-size:14px;
  display:inline-block;
  line-height:20px;
  vertical-align:middle
}

.vue-pure-target .mp-widget .mp-small-rating-widget-wrapper{
  display:flex !important;
  align-items:center;
  flex-wrap:wrap
}

.vue-pure-target .mp-widget .mp-small-rating-widget-review-rating{
  height:auto
}

.vue-pure-target .mp-widget .mp-small-rating-widget-rating-value{
  color:#00aafa
}

.vue-pure-target .file__text{
  margin-bottom:0
}

.vue-pure-target button.main-button,.vue-pure-target input.main-button{
  line-height:1.15
}

.vue-pure-target button.main-button span,.vue-pure-target input.main-button span{
  font-size:18px;
  line-height:inherit !important
}

.vue-pure-target .login-box h1,.vue-pure-target .login-box h2,.vue-pure-target .login-box h3{
  color:#69a4c9;
  font-weight:normal
}

.vue-pure-target .title.blog-item-title a{
  overflow-wrap:break-word
}

@media(min-width: 300px){
  .vue-pure-target .page-main__wrapper#main{
    display:flex;
    flex-direction:column
  }

  .vue-pure-target .page-main__container{
    width:100%
  }
}

@media(max-width: 300px){
  .vue-pure-target .page-main__wrapper.top-margin{
    margin-top:20px
  }
}

@media(max-width: 640px){
  .vue-pure-target .page-main__wrapper.top-margin{
    margin-top:20px
  }
}

@media(max-width: 1249px){
  .vue-pure-target .page-wrapper{
    width:100%
  }

  .vue-pure-target .page-main__wrapper.top-margin{
    margin-top:65px
  }

  .vue-pure-target .product-main-header__heading h1{
    font-size:3rem;
    line-height:1.3;
    margin:0 0 10px 0
  }

  .vue-pure-target .product-breadcrumbs{
    order:-1;
    margin-bottom:20px;
    font-size:14px;
    color:#999
  }

  .vue-pure-target .mp-product-widget-content div{
    font-size:16px
  }
}

@media(min-width: 1280px){
  .vue-pure-target .page-main__container{
    max-width:1280px
  }
}

.popup_open{
  overflow:hidden
}

@media(min-width: 1280px){
  .popup_open{
    overflow:auto
  }
}

.mp-icon-star::before,.mp-icon-star-empty::before{
  color:#00aafa !important
}

.ly-button-wrapper{
  width:auto !important;
  height:auto !important;
  border:none !important
}