.main-sprite__before.shop-icon__quantity-reduce:before,.main-sprite__before.shop-icon__quantity-increase:before{
  background-position-x:-6px;
  height:17px;
  display:block
}

.main-sprite__before.shop-icon__quantity-reduce:before{
  background-position-y:-180px
}

.main-sprite__before.shop-icon__quantity-increase:before{
  background-position-y:-199px
}

.main-sprite__before.shop-icon__left-arrow:before,.main-sprite__before.shop-icon__right-arrow:before{
  height:20px;
  width:24px;
  z-index:1
}

.main-sprite__before.shop-icon__left-arrow{
  position:relative
}

.main-sprite__before.shop-icon__left-arrow:before{
  position:absolute;
  left:10px;
  top:12px;
  background-position-y:-944px
}

.main-sprite__before.shop-icon__left-arrow:hover:before{
  background-position-y:-966px
}

.main-sprite__before.shop-icon__right-arrow{
  position:relative
}

.main-sprite__before.shop-icon__right-arrow:before{
  background-position-y:-985px
}

.main-sprite__before.shop-icon__right-arrow:hover:before{
  background-position-y:-1008px
}

.main-sprite.shop-icon__other-product-arrow{
  width:48px;
  height:48px;
  background-position-x:-31px
}

.main-sprite__before.shop-icon__payment-list::before{
  width:23px;
  height:20px;
  background-position-y:-657px;
  margin-bottom:-6px
}

.main-sprite__before.shop-icon__condition-list::before{
  width:23px;
  height:14px;
  background-position-y:-642px;
  margin-bottom:-4px
}

.main-sprite.shop-icon__compare-icon,.product-details-info:not(.sunglasses-info) .main-sprite__checkbox.shop-icon__compare-icon-checkbox input:not(:checked)+label::after{
  width:20px;
  height:16px;
  background-position-x:-5px;
  background-position-y:-624px
}

.product-details-info:not(.sunglasses-info) .main-sprite__checkbox.shop-icon__compare-icon-checkbox input:checked+label::after{
  width:20px;
  height:10px;
  background-position-x:-5px;
  background-position-y:-614px;
  top:10px
}

.product-other-prev .main-sprite.shop-icon__other-product-arrow{
  background-position-y:-96px
}

.product-other-next .main-sprite.shop-icon__other-product-arrow{
  background-position-y:-48px
}

@media screen and (max-width: 1249px){
  .main-sprite__before.shop-icon__quantity-reduce:before,.main-sprite__before.shop-icon__quantity-increase:before{
    top:37%;
    position:absolute
  }
}

@media screen and (max-width: 640px){
  .main-sprite__before.shop-icon__quantity-reduce:before,.main-sprite__before.shop-icon__quantity-increase:before{
    top:31%;
    position:absolute
  }
}