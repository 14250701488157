.c-popup-overlay[data-v-16b706c2]{
  position:fixed;
  z-index:10001;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color:rgba(0,0,0,.5);
  display:flex;
  justify-content:center;
  align-items:center;
  transition:opacity .3s ease
}
.c-popup[data-v-16b706c2]{
  position:relative;
  box-sizing:border-box;
  background-color:#fff;
  border-radius:5px;
  max-height:100vh;
  margin:0 20px;
  transition:all .3s ease;
  overflow:auto
}
.c-popup__head[data-v-16b706c2]{
  position:absolute;
  top:0;
  right:0;
  padding:20px;
  padding-bottom:0
}
.c-popup__content[data-v-16b706c2]{
  position:relative;
  z-index:3
}
.c-popup__close[data-v-16b706c2]{
  position:relative;
  width:19px;
  height:19px;
  background-image:url(/var/resources/static/images/closeaf0c.svg);
  background-repeat:no-repeat;
  background-size:contain;
  background-position:center center;
  border:none;
  cursor:pointer;
  z-index:4
}
.c-popup__close[data-v-16b706c2]:focus{
  outline:none
}
.c-popup-enter[data-v-16b706c2]{
  opacity:0
}
.c-popup-leave-active[data-v-16b706c2]{
  opacity:0
}
.c-popup-enter .c-popup[data-v-16b706c2],.c-popup-leave-active .c-popup[data-v-16b706c2]{
  transform:scale(1.1)
}
.c-popup-overlay.c-congratuiation .c-popup__content[data-v-16b706c2]{
  padding:0 20px 28px
}
@media (min-width: 640px){
.c-popup-overlay.c-congratuiation .c-popup[data-v-16b706c2]{
    max-width:420px
}
.c-popup-overlay.c-congratuiation .c-popup__content[data-v-16b706c2]{
    padding:36px 45px
}
}
.c-popup-overlay.c-request-callback .c-popup[data-v-16b706c2]{
  max-width:340px
}
.c-popup-overlay.c-request-callback .c-popup__content[data-v-16b706c2]{
  padding:20px
}
.c-popup-overlay.c-doctor-appointment .c-popup[data-v-16b706c2]{
  max-width:288px
}
.c-popup-overlay.c-doctor-appointment .c-popup__content[data-v-16b706c2]{
  padding:22px
}
.c-popup-overlay.c-contact-us .c-popup[data-v-16b706c2]{
  max-width:312px
}
.c-popup-overlay.c-contact-us .c-popup__content[data-v-16b706c2]{
  padding:20px
}
.c-popup-overlay.c-city-selection .c-popup[data-v-16b706c2]{
  max-width:600px;
  width:100%
}
.c-popup-overlay.c-city-selection .c-popup__content[data-v-16b706c2]{
  padding:30px 75px 60px
}
@media(max-width: 639px){
.c-popup-overlay.c-city-selection .c-popup__content[data-v-16b706c2]{
    padding:28px 20px
}
}
.c-popup-overlay.c-notification[data-v-16b706c2]{
  z-index:10003
}
.c-popup-overlay.product-main-block__out-off-production .c-popup[data-v-16b706c2]{
  background-color:#e9f4ff
}
@media screen and (orientation: landscape)and (max-device-width: 900px){
.c-popup-overlay.c-notification .c-popup[data-v-16b706c2],.c-popup-overlay.c-base .c-popup[data-v-16b706c2]{
    max-width:300px;
    width:100%
}
}
@media(max-width: 639px){
.c-popup-overlay.c-notification .c-popup[data-v-16b706c2],.c-popup-overlay.c-base .c-popup[data-v-16b706c2]{
    max-width:320px;
    width:100%
}
}
@media(min-width: 640px){
.c-popup-overlay.c-notification .c-popup[data-v-16b706c2],.c-popup-overlay.c-base .c-popup[data-v-16b706c2]{
    max-width:600px;
    width:100%
}
}
.c-popup-overlay.c-notification .c-popup__head[data-v-16b706c2],.c-popup-overlay.c-base .c-popup__head[data-v-16b706c2]{
  padding:12px 12px 0;
  padding-bottom:0
}
@media (min-width: 640px){
.c-popup-overlay.c-notification .c-popup__head[data-v-16b706c2],.c-popup-overlay.c-base .c-popup__head[data-v-16b706c2]{
    padding:20px
}
}
.c-popup-overlay.c-notification .c-popup__close[data-v-16b706c2],.c-popup-overlay.c-base .c-popup__close[data-v-16b706c2]{
  width:16px;
  height:16px
}
@media (min-width: 640px){
.c-popup-overlay.c-notification .c-popup__close[data-v-16b706c2],.c-popup-overlay.c-base .c-popup__close[data-v-16b706c2]{
    width:19px;
    height:19px
}
}
.c-popup-overlay.c-notification .c-popup__content[data-v-16b706c2],.c-popup-overlay.c-base .c-popup__content[data-v-16b706c2]{
  text-align:center;
  overflow-wrap:break-word;
  word-wrap:break-word;
  word-break:break-word;
  -webkit-hyphens:none;
          hyphens:none
}
@media screen and (orientation: landscape)and (max-device-width: 900px){
.c-popup-overlay.c-notification .c-popup__content[data-v-16b706c2],.c-popup-overlay.c-base .c-popup__content[data-v-16b706c2]{
    padding:32px 20px
}
}
@media(max-width: 639px){
.c-popup-overlay.c-notification .c-popup__content[data-v-16b706c2],.c-popup-overlay.c-base .c-popup__content[data-v-16b706c2]{
    padding:32px 20px
}
}
@media(min-width: 640px){
.c-popup-overlay.c-notification .c-popup__content[data-v-16b706c2],.c-popup-overlay.c-base .c-popup__content[data-v-16b706c2]{
    padding:60px 75px 60px 75px
}
}
@media (min-width: 640px){
.delivery-popup.c-base .c-popup[data-v-16b706c2]{
    max-width:420px;
    padding:0
}
.delivery-popup.c-base .c-popup__content[data-v-16b706c2]{
    padding:0 20px 36px
}
}
.product-add-to-cart.c-base .c-popup[data-v-16b706c2]{
  padding:40px 40px 40px;
  transition:all .3s ease;
  max-width:800px
}
.product-add-to-cart.c-base .c-popup__head[data-v-16b706c2]{
  padding-right:20px
}
.product-add-to-cart.c-base .c-popup__content[data-v-16b706c2]{
  padding:0
}