@keyframes lds-spinner-data-v-04860cbb{
0%{
    opacity:1
}
100%{
    opacity:0
}
}
.lds-spinner[data-v-04860cbb]{
  width:32px;
  height:32px;
  margin:0 auto
}
.lds-spinner div[data-v-04860cbb]{
  transform-origin:16px 16px;
  animation:lds-spinner-data-v-04860cbb 1.2s linear infinite
}
.lds-spinner div[data-v-04860cbb]:after{
  content:"";
  display:block;
  position:absolute;
  top:23px;
  left:15px;
  width:2.4px;
  height:9px;
  border-radius:2px;
  background:#9a9a9a
}
.lds-spinner div[data-v-04860cbb]:nth-child(1){
  transform:rotate(0deg);
  animation-delay:-1.1s
}
.lds-spinner div[data-v-04860cbb]:nth-child(2){
  transform:rotate(30deg);
  animation-delay:-1s
}
.lds-spinner div[data-v-04860cbb]:nth-child(3){
  transform:rotate(60deg);
  animation-delay:-0.9s
}
.lds-spinner div[data-v-04860cbb]:nth-child(4){
  transform:rotate(90deg);
  animation-delay:-0.8s
}
.lds-spinner div[data-v-04860cbb]:nth-child(5){
  transform:rotate(120deg);
  animation-delay:-0.7s
}
.lds-spinner div[data-v-04860cbb]:nth-child(6){
  transform:rotate(150deg);
  animation-delay:-0.6s
}
.lds-spinner div[data-v-04860cbb]:nth-child(7){
  transform:rotate(180deg);
  animation-delay:-0.5s
}
.lds-spinner div[data-v-04860cbb]:nth-child(8){
  transform:rotate(210deg);
  animation-delay:-0.4s
}
.lds-spinner div[data-v-04860cbb]:nth-child(9){
  transform:rotate(240deg);
  animation-delay:-0.3s
}
.lds-spinner div[data-v-04860cbb]:nth-child(10){
  transform:rotate(270deg);
  animation-delay:-0.2s
}
.lds-spinner div[data-v-04860cbb]:nth-child(11){
  transform:rotate(300deg);
  animation-delay:-0.1s
}
.lds-spinner div[data-v-04860cbb]:nth-child(12){
  transform:rotate(330deg);
  animation-delay:0s
}