.added-to-success .added-to-cart-popup{
  z-index:1200000;
  position:fixed;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  box-shadow:0 0 16px 8px rgba(0,0,0,.2);
  border:1px solid #e1e1e1;
  padding:30px 24px 20px 24px;
  border-radius:6px;
  background-color:#fff;
  width:95%;
  max-width:560px
}
.added-to-success .added-to-cart-popup__title{
  color:#373737;
  font-size:24px;
  margin-bottom:26px;
  line-height:1.5;
  text-transform:uppercase;
  text-align:center;
  font-weight:700
}
@media (min-width: 640px){
.added-to-success .added-to-cart-popup__title{
    text-align:left
}
}
.added-to-success .added-to-cart-popup__title--blue{
  color:#00aafa;
  font-size:18px;
  margin-bottom:12px;
  text-align:left
}
@media (min-width: 640px){
.added-to-success .added-to-cart-popup__title--blue{
    font-size:24px;
    margin-bottom:26px
}
}
.added-to-success .added-to-cart-popup__close-link{
  background:url(/var/resources/static/images/close_gray5c7a.png) center center no-repeat;
  height:32px;
  width:32px;
  border-radius:0;
  border:none;
  margin:0;
  display:block;
  position:absolute;
  right:0;
  top:0;
  outline-style:none;
  z-index:999
}
.added-to-success .added-to-cart-popup__product{
  display:flex;
  flex-direction:column;
  margin-bottom:22px;
  text-align:left
}
@media (min-width: 640px){
.added-to-success .added-to-cart-popup__product{
    flex-direction:row
}
}
.added-to-success .added-to-cart-popup__product--recomended{
  flex-direction:row
}
.added-to-success .added-to-cart-popup__product--recomended:last-child{
  margin-bottom:10px
}
.added-to-success .added-to-cart-popup__image{
  padding:6px;
  border:2px solid #ddd;
  margin-right:10px;
  width:90px;
  height:auto
}
.added-to-success .added-to-cart-popup__info-wrapper{
  width:100%;
  display:flex;
  align-items:flex-start;
  justify-content:space-between;
  flex-wrap:wrap;
  flex-direction:column
}
@media (min-width: 640px){
.added-to-success .added-to-cart-popup__info-wrapper{
    flex-direction:row
}
}
.added-to-success .added-to-cart-popup__text-wrapper{
  display:flex;
  flex-direction:column;
  flex:1;
  padding-right:12px;
  padding-left:10px
}
.added-to-success .added-to-cart-popup__text-wrapper--title{
  border-bottom:1px solid #f3f3f3;
  margin-bottom:12px;
  padding:0
}
@media (min-width: 640px){
.added-to-success .added-to-cart-popup__text-wrapper--title{
    padding-right:12px;
    margin-bottom:0;
    border:none
}
}
@media (min-width: 640px){
.added-to-success .added-to-cart-popup__text-wrapper{
    padding-left:0
}
}
.added-to-success .added-to-cart-popup__text-info{
  margin-bottom:0;
  text-transform:uppercase;
  font-size:12px;
  line-height:1
}
.added-to-success .added-to-cart-popup__text-info--title{
  font-size:20px;
  text-align:center
}
@media (min-width: 640px){
.added-to-success .added-to-cart-popup__text-info--title{
    text-align:left
}
}
@media (min-width: 640px){
.added-to-success .added-to-cart-popup__text-info{
    font-size:20px
}
}
@media (min-width: 1280px){
.added-to-success .added-to-cart-popup__text-info{
    font-size:18px
}
}
.added-to-success .added-to-cart-popup__text-info--name{
  color:#000;
  display:-webkit-box;
  -webkit-line-clamp:3;
  -webkit-box-orient:vertical;
  overflow:hidden;
  text-transform:none;
  line-height:1.4
}
.added-to-success .added-to-cart-popup__text-info--name:hover{
  color:#000
}
.added-to-success .added-to-cart-popup__text-info--hot{
  color:#ee4981
}
.added-to-success .added-to-cart-price--hot{
  color:#ee4981
}
.added-to-success .added-to-cart-price--old{
  position:relative;
  font-size:12px;
  line-height:1;
  color:#c4c4c4;
  font-weight:400;
  vertical-align:text-top
}
.added-to-success .added-to-cart-price--old::after{
  content:"";
  display:block;
  position:absolute;
  left:0;
  top:calc(50% - 1px);
  width:100%;
  height:1px;
  background-color:#c4c4c4
}
.added-to-success .added-to-cart-price--old span{
  font-size:12px;
  line-height:1
}
.added-to-success .added-to-cart-popup__text-info--price{
  font-size:22px;
  font-weight:700;
  white-space:nowrap
}
@media (min-width: 640px){
.added-to-success .added-to-cart-popup__text-info--price{
    font-size:31px
}
}
@media (min-width: 1280px){
.added-to-success .added-to-cart-popup__text-info--price{
    font-size:22px
}
}
.added-to-success .added-to-cart-popup__text-info--price-blue{
  color:#00aafa;
  font-weight:700;
  padding-top:4px;
  font-size:16px
}
@media (min-width: 768px){
.added-to-success .added-to-cart-popup__text-info--price-blue{
    font-size:32px
}
}
@media (min-width: 768px){
.added-to-success .added-to-cart-popup__text-info--price-blue{
    font-size:22px
}
}
.added-to-success .added-to-cart-popup__text-info--price span{
  font-family:"RUBSN";
  padding-left:2px;
  line-height:0
}
.added-to-success .added-to-cart-popup__attributes{
  width:-webkit-fit-content;
  width:-moz-fit-content;
  width:fit-content;
  display:flex;
  flex-direction:column;
  flex-wrap:wrap;
  margin-top:10px;
  margin-bottom:18px;
  grid-gap:6px 20px;
  gap:6px 20px;
  flex-wrap:wrap;
  max-height:50px;
  width:100%
}
@media (min-width: 768px){
.added-to-success .added-to-cart-popup__attributes{
    padding-bottom:0;
    margin-bottom:0
}
}
@media (min-width: 768px){
.added-to-success .added-to-cart-popup__attributes{
    max-height:none
}
}
.added-to-success .added-to-cart-popup__images{
  align-self:center;
  width:88px;
  height:48px;
  object-fit:contain;
  margin:0 0 12px 0
}
@media (min-width: 640px){
.added-to-success .added-to-cart-popup__images{
    width:150px;
    height:82px;
    margin:0 20px 0 0;
    align-self:flex-start
}
}
.added-to-success .added-to-cart-popup__attributes .added-to-cart-popup__paragraph{
  margin-bottom:0;
  color:#373737;
  font-size:12px;
  line-height:1.3;
  width:calc(50% - 10px)
}
.added-to-success .added-to-cart-popup__buttons{
  display:flex;
  justify-content:space-between;
  flex-wrap:wrap
}
.added-to-success .added-to-cart-popup__gift{
  align-items:center;
  border-bottom:1px solid #d8d8d8;
  padding-bottom:15px
}
.added-to-success .added-to-cart-popup__gift-text{
  max-width:363px;
  font-size:16px;
  line-height:16px;
  margin-bottom:0
}
.added-to-success .added-to-cart-popup__gift-image{
  margin-right:15px;
  width:102px
}
.added-to-success .added-to-cart-button{
  display:none;
  background-color:#00aafa;
  color:#fff;
  border:1px solid #00aafa;
  text-transform:uppercase;
  border-radius:25px;
  font-size:13px;
  padding:6px 30px;
  line-height:1;
  height:auto;
  transition:all .3s
}
.added-to-success .added-to-cart-button:hover,.added-to-success .added-to-cart-button--active{
  background-color:#fff;
  color:#00aafa
}
.added-to-success .added-to-cart-button:focus{
  outline:none
}
@media (min-width: 640px){
.added-to-success .added-to-cart-button{
    display:block
}
}
.added-to-success .added-to-cart-button-icon{
  background-color:transparent;
  border:none;
  align-self:flex-start;
  height:auto;
  cursor:pointer
}
@media (min-width: 640px){
.added-to-success .added-to-cart-button-icon{
    display:none
}
}
.added-to-success .added-to-cart-button-icon:disabled:hover{
  background-color:transparent
}
.added-to-success .added-to-cart-button-icon:focus{
  outline:none
}
.added-to-success .added-to-cart-popup__button-wrapper{
  display:flex;
  align-items:flex-end
}
.added-to-success .added-to-cart-popup__recomend{
  padding:12px 0 0
}
.added-to-success .added-to-cart-popup__text-left{
  display:flex;
  flex-direction:row-reverse;
  justify-content:space-between;
  align-items:center;
  width:100%
}
@media (min-width: 640px){
.added-to-success .added-to-cart-popup__text-left{
    text-align:right;
    display:block;
    width:auto
}
}
.added-to-success .added-to-cart-popup__text-counter{
  display:flex;
  align-items:center
}
@media (min-width: 640px){
.added-to-success .added-to-cart-popup__text-counter{
    padding-top:20px
}
}
.added-to-success .added-to-cart-popup__text-counter .product__counter{
  padding:0;
  border:none;
  background-color:transparent;
  width:auto;
  height:auto
}
.added-to-success .added-to-cart-popup__text-counter .product__counter:disabled:hover{
  background-color:transparent
}
.added-to-success .added-to-cart-popup__text-counter .product__counter:focus{
  outline:none
}
.added-to-success .added-to-cart-popup__text-amount{
  font-size:12px;
  color:#373737;
  width:30px;
  text-align:center
}
.added-to-success .added-to-cart-popup__buttons .added-to-cart-popup__button{
  border-radius:6px;
  display:flex;
  align-items:center;
  justify-content:center;
  padding:0 10px 0;
  height:38px;
  line-height:38px;
  min-width:200px;
  box-sizing:border-box;
  font-size:13px;
  margin-bottom:10px;
  text-transform:uppercase;
  color:#00aafa;
  cursor:pointer;
  border:1px solid #00aafa;
  overflow:visible;
  outline:none
}
.added-to-success .added-to-cart-popup__button svg{
  width:20px;
  padding-left:10px
}
.added-to-success .added-to-cart-popup__buttons .added-to-cart-popup__button--right{
  color:#fff;
  border:1px solid #ee4981;
  background-color:#ee4981
}
.added-to-success .added-to-cart-popup__button--right:hover{
  border:1px solid #ee4981;
  background-color:#fff;
  color:#ee4981
}
.added-to-success .added-to-cart-popup__buttons .added-to-cart-popup__button--left{
  border:1px solid #00aafa;
  background-color:#fff
}
.added-to-success .added-to-cart-popup__buttons .added-to-cart-popup__button--left svg{
  transform:rotate(180deg)
}
@media screen and (max-width: 1249px){
.added-to-success .added-to-cart-popup__button svg{
    width:30px
}
}
@media screen and (max-width: 640px){
.added-to-success .added-to-cart-popup__button{
    text-transform:none;
    width:100%
}
}