.main-sprite,.main-sprite__before:before,.main-sprite__after:after,.main-sprite__checkbox input+label::after{
  background-image:url(/var/resources/static/images/main-icons-sprite1c2b.png);
  background-repeat:no-repeat;
  background-position-x:0;
  background-position-y:1000px;
  width:28px;
  height:16px
}

.main-sprite__before:before,.main-sprite__after:after,.main-sprite__checkbox input+label::after{
  content:"";
  display:inline-block
}