.main-sprite__before.header-icon{
  position:absolute;
  top:50%;
  margin-top:-10px;
  margin-left:-30px
}

.main-sprite__before.header-icon__account:before,.main-sprite__before.header-icon__bonus:before,.main-sprite__before.header-icon__cart:before,.main-sprite__before.header-icon__club-card:before{
  position:absolute;
  left:-28px
}

.main-sprite__before.header-icon__basket:before,.main-sprite__before.header-icon__compare:before{
  position:absolute;
  left:-32px;
  top:-2px
}

.main-sprite__before.header-icon__basket:before{
  background-position-y:0;
  margin-bottom:-3px;
  margin-right:5px
}

.main-sprite__before.header-icon__account:before{
  background-position-y:-16px;
  background-position-x:-5px;
  width:16px;
  margin-right:12px;
  margin-bottom:-2px;
  top:-4px
}

.main-sprite__before.header-icon__compare:before{
  background-position-y:-34px;
  margin-bottom:-4px;
  margin-right:5px
}

.main-sprite__before.header-icon__bonus:before{
  background-position-y:-50px;
  background-position-x:-3px;
  height:19px;
  width:23px;
  margin-right:5px;
  top:-4px
}

.main-sprite__before.header-icon__call-us:before{
  background-position-y:-71px;
  position:absolute;
  left:-33px;
  top:-4px
}

.main-sprite__before.header-icon__club-card:before{
  background-position-y:-1236px;
  background-position-x:-4px;
  top:3px
}

.main-sprite__before.header-icon__search-submit-button:before{
  background-position-y:-100px;
  margin-top:2px
}

.fixed-header__container .header-icon__basket:before{
  background-position-y:-294px;
  margin-bottom:-3px;
  margin-right:3px;
  margin-top:-4px
}

.fixed-header__container .header-icon__account::before{
  background-position-y:-258px
}

.fixed-header__container .header-icon__compare::before{
  background-position-y:-276px;
  top:-4px
}

.fixed-header__container .header-icon__call-us::before{
  background-position-y:-242px
}

.fixed-header__container .widget .widget-link::before{
  left:0;
  margin-left:0
}

.fixed-header__container .widget.account:hover .header-icon__account::before{
  background-position-y:-16px
}

.fixed-header__container .widget.cart:hover .header-icon__basket::before{
  background-position-y:-1071px
}

.fixed-header__container .widget.cart:hover .null .header-icon__basket::before{
  background-position-y:-294px
}

.fixed-header__container .widget.comparison:hover .header-icon__compare::before{
  background-position-y:-34px
}

.fixed-header__container .widget.comparison:hover .null .header-icon__compare::before{
  background-position-y:-276px
}

.fixed-header__container .widget.callback:hover .header-icon__call-us::before{
  background-position-y:-71px
}

.fixed-header__container .widget.cart:hover .header-icon__basket::before{
  background-position-y:-1071px
}