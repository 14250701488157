.app-store .link-app-store{
  border:none;
  line-height:30px;
  background-image:url(/var/resources/static/images/main-icons-sprite1c2b.png);
  background-repeat:no-repeat;
  width:173px;
  height:51px
}

.app-store .link-app-store.google,.app-store .link-app-store.apple{
  background-position-x:-82px
}

.app-store .link-app-store.google{
  background-position-y:0
}

.app-store .link-app-store.apple{
  background-position-y:-51px
}