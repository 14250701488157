.main-sprite__before.page-footer__brands-wrapper{
  text-align:center
}

.main-sprite__before.page-footer__brands-wrapper::before{
  background-position-x:-255px;
  background-position-y:0;
  width:500px;
  height:120px
}

@media screen and (max-width: 640px){
  .main-sprite__before.page-footer__brands-wrapper::before{
    display:none
  }
}