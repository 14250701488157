.product-grid__wrap .mp-widget{
  margin-top:10px;
  display:flex;
  justify-content:center
}

.product-grid__column{
  border:1px solid #f3f3f3;
  min-width:295px
}

.product-grid__column .product-category-add-to-card{
  display:block;
  position:relative;
  width:155px;
  flex-basis:155px;
  margin:0
}

.snippet-wrap{
  position:relative;
  padding:20px 30px 30px;
  border:1px solid transparent;
  box-sizing:border-box;
  overflow:hidden;
  height:100%
}

.snippet-wrap:hover{
  border-color:#f2f2f2
}

.snippet-wrap:hover .snippet__controller{
  opacity:1;
  z-index:10
}

.snippet-wrap .snippet.product{
  height:100%;
  display:flex;
  flex-direction:column
}

.snippet-wrap .snippet.product .snippet__params{
  flex:1
}

.snippet__picture{
  display:flex;
  width:160px;
  height:160px;
  margin:0 auto 10px;
  justify-content:center;
  align-items:center
}

.snippet__picture-link{
  border:none;
  transition:.2s ease-in
}

.snippet__price{
  margin-bottom:10px;
  min-height:53px
}

.snippet__params-item{
  padding:0;
  margin:0 0 5px;
  list-style:none;
  text-align:left
}

.snippet__params-item:before{
  content:none
}

.snippet__params-item:last-child{
  margin:0
}

.params-row{
  display:grid;
  grid-template-columns:2fr 5px 1fr;
  margin-bottom:10px
}

.snippet__params>.snippet__params-list>.params-row{
  white-space:nowrap
}

.params-row__left{
  grid-column:1;
  font-size:10px;
  line-height:1.1;
  color:#9e9e9e;
  margin-right:5px
}

.params-row__right{
  grid-column:3;
  font-size:10px;
  line-height:1.1;
  color:#000
}

.snippet{
  padding:30px;
  border:1px solid #f3f3f3;
  position:relative
}

.snippet .sale-bubble img{
  width:64px;
  height:auto
}

.snippet__params{
  height:106px
}

.snippet__controller{
  display:flex;
  justify-content:space-between;
  margin-top:30px;
  padding:0;
  align-items:center;
  opacity:1;
  z-index:-2;
  transition:opacity .25s ease
}

.snippet__controller:before{
  content:"";
  width:20px;
  height:20px;
  flex:1 1 20px;
  display:block;
  min-width:20px;
  max-width:20px
}

@media(max-width: 1250px){
  .snippet__controller{
    opacity:1
  }
}

.snippet__controller .snippet__compare{
  position:relative;
  left:auto;
  cursor:pointer;
  visibility:hidden
}

.snippet__compare{
  grid-column:3
}

.snippet__compare input{
  display:none
}

.catalog__grid{
  display:flex;
  flex-wrap:wrap
}

.catalog__filter{
  flex:0 100%;
  max-width:295px;
  padding:0 30px;
  display:none
}

.catalog__products{
  flex:1 0;
  margin-top:0;
  padding:0
}

.category-products .products{
  width:auto;
  margin:0;
  padding:0
}

.catalog__filter-block{
  padding:0;
  border-bottom:1px solid #f2f2f2
}

.catalog__filter-block:last-of-type{
  border-bottom:none
}

.catalog__filter{
  margin-right:30px;
  border:1px solid #f2f2f2
}

.collapsed-block__content{
  max-height:0;
  overflow:hidden;
  transition:.25s
}

.collapsed-block--active .collapsed-block__header:before{
  transform:translateY(-50%) rotate(180deg)
}

.collapsed-block--active .collapsed-block__content{
  max-height:99999px
}

.filter-block--active .filter-block__title{
  position:relative;
  padding-right:18px
}

.filter-block--active .filter-block__title:after{
  content:"";
  display:block;
  position:absolute;
  width:8px;
  height:8px;
  top:calc(50% + 2px);
  right:0;
  transform:translateY(-50%);
  background-color:#00a9fa;
  border-radius:50%
}

.product-filter ul{
  padding:0;
  margin:0;
  list-style:none
}

.product-filter li:before{
  content:none
}

.filter-block__header{
  position:relative;
  padding:20px 18px 20px 0
}

.filter-block__content{
  overflow:hidden
}

.filter-block__content .checkbox-usual:last-of-type{
  margin-bottom:20px
}

.filter-block__title{
  display:inline-block;
  margin:0;
  font-size:16px;
  line-height:1.2;
  font-weight:700
}

.collapsed-block__header{
  padding-left:26px;
  cursor:pointer
}

.collapsed-block__header:before{
  content:"";
  display:block;
  position:absolute;
  width:11px;
  height:6px;
  left:0;
  top:calc(50% + 1px);
  transform:translateY(-50%);
  background:url(/var/resources/static/images/icon-down-primary74ec.svg) center center no-repeat;
  transition:.25s ease
}

.checkbox-usual{
  position:relative;
  padding:10px 0 10px 30px
}

.checkbox-usual__label{
  display:block;
  font-size:14px;
  text-transform:none;
  cursor:pointer
}

.checkbox-usual__label:before{
  content:"";
  position:absolute;
  left:0;
  top:50%;
  transform:translateY(-50%);
  display:block;
  width:20px;
  height:20px;
  border:1px solid #d8d8d8
}

.checkbox-usual__label:after{
  content:"";
  display:block;
  position:absolute;
  left:9px;
  top:calc(50% - 2px);
  transform:translateY(-50%) rotate(45deg);
  width:5px;
  height:10px;
  border-bottom:1px solid #00a9fa;
  border-right:1px solid #00a9fa;
  opacity:0;
  transition:.25s ease
}

.checkbox-usual__input{
  display:none
}

.checkbox-usual__input:checked+.checkbox-usual__label:after{
  opacity:1
}

.filter-block__price{
  display:grid;
  grid-template-columns:1fr 1fr;
  grid-gap:20px
}

.filter-block__price-input{
  width:100%;
  padding:15px;
  background-color:#f2f2f2;
  border:none;
  border-radius:32px;
  box-sizing:border-box
}

.filter-block__more{
  margin-bottom:20px;
  padding:0;
  color:#00a9fa;
  background-color:transparent;
  border:none;
  border-bottom:1px solid #d8d8d8;
  border-radius:0;
  font-size:14px;
  line-height:1.5;
  text-transform:none;
  cursor:pointer
}

.catalog__filter-refresh{
  width:100%;
  margin-top:20px;
  padding:16px 0;
  background-color:transparent;
  color:#00a9fa;
  border:1px solid #00a9fa;
  border-radius:25px;
  font-size:14px;
  text-transform:none
}

.snippet__controller.visible__button{
  opacity:1
}

.snippet .snippet__params>.snippet__params-item{
  white-space:nowrap
}