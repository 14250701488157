.checkout-fixed-header__wrapper{
  box-shadow:0 0 6px rgba(0,0,0,.3);
  display:none;
  position:fixed;
  width:100%;
  left:0;
  top:0;
  z-index:40;
  background-color:#fff
}

.page-target-cart,.page-target-checkout .checkout-header-logo__wrapper{
  margin-left:30px
}

.mobile-checkout-fixed-header__wrapper{
  box-shadow:0 0 6px rgba(0,0,0,.3);
  display:none;
  width:100%;
  position:relative;
  left:0;
  top:0;
  z-index:40;
  background-color:#fff
}

.mobile-checkout-fixed-header__wrapper .checkout-fixed-header{
  z-index:111
}

.mobile-checkout-fixed-header__wrapper .mobile-menu-container{
  top:0;
  padding-top:56px
}

.page-target-cart,.page-target-checkout{
  display:block
}

.checkout-fixed-header{
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
  position:relative;
  padding:0;
  height:56px;
  max-width:1250px;
  margin:0 auto
}

.checkout-show-full-header{
  background-image:url(/var/resources/static/images/checkout-header-show-btn574b.svg);
  background-color:#fff;
  background-size:contain;
  border-radius:0;
  height:17px;
  width:32px;
  padding:0px;
  border:none;
  cursor:pointer
}

.checkout-show-full-header:focus{
  outline:none
}

.checkout-header-logo__wrapper .checkout-header-logo-link{
  border-bottom:0
}

.checkout-header-logo__wrapper .checkout-header-logo{
  height:40px
}

.checkout-header-cityes__wrapper{
  font-style:normal;
  font-weight:normal;
  font-size:12px;
  line-height:16px;
  display:flex;
  cursor:pointer;
  flex-direction:row;
  justify-content:space-between;
  align-items:center
}

@media(max-width: 576px){
  .checkout-header-logo__wrapper .checkout-header-logo{
    height:24px
  }
}

@media screen and (max-width: 1249px){
  .checkout-fixed-header__wrapper{
    display:none !important
  }

  .mobile-checkout-fixed-header__wrapper{
    display:block !important
  }
}

@media(max-width: 1270px){
  .checkout-fixed-header{
    padding:0 20px
  }
}

.checkout-mobile-menu-block{
  width:32px;
  display:inline-block;
  vertical-align:middle;
  position:relative
}

.checkout-mobile-menu-btn{
  height:9px;
  position:relative;
  cursor:pointer;
  display:block;
  border-bottom:0;
  z-index:111;
  cursor:pointer
}

.checkout-mobile-menu-block .checkout-mobile-menu-btn:before,.checkout-mobile-menu-block .checkout-mobile-menu-btn:after,.checkout-mobile-menu-block .checkout-mobile-menu-btn .checkout-middle-line{
  content:"";
  width:24px;
  height:1px;
  display:block;
  position:absolute;
  left:0;
  background-color:#00a9fa;
  transition:all .3s
}

.checkout-mobile-menu-block .checkout-mobile-menu-btn:before{
  top:0
}

.checkout-mobile-menu-block .checkout-mobile-menu-btn:after{
  bottom:0
}

.checkout-middle-line{
  top:4px
}

.checkout-mobile-menu-block .checkout-mobile-menu-btn.active .checkout-middle-line{
  opacity:0
}

.checkout-mobile-menu-block .checkout-mobile-menu-btn.active:before{
  transform:rotate(45deg);
  background-color:#fb1e9a;
  width:14px;
  top:5px;
  left:5px
}

.checkout-mobile-menu-block .checkout-mobile-menu-btn.active:after{
  transform:rotate(-45deg);
  background-color:#fb1e9a;
  width:14px;
  top:5px;
  left:5px
}

.checkout-close-note{
  opacity:0;
  position:absolute;
  top:0;
  left:30px;
  display:block;
  color:#fb1e9a;
  line-height:14px;
  font-size:14px;
  opacity:0;
  transition:opacity .3s;
  pointer-events:none
}

.checkout-mobile-menu-btn.active .checkout-close-note{
  opacity:1;
  pointer-events:all
}

.checkout-city-select-icon{
  width:16px;
  height:16px;
  margin-left:10px;
  border:1px solid #f2f2f2;
  border-radius:10px;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center
}