.footer-nav__container .social_link span.footer_facebook,.footer-nav__container .social_link span.footer_vk,.footer-nav__container .social_link span.footer_im,.footer-nav__container .social_link span.footer_twitter,.footer-nav__container .social_link span.footer_odnoklassniki,.footer-nav__container .social_link span.footer_instagram{
  background-image:url(/var/resources/static/images/main-icons-sprite1c2b.png);
  background-repeat:no-repeat;
  width:30px;
  height:28px;
  float:left;
  margin-right:15px;
  background-position-x:0
}

.footer-nav__container .social_link span.footer_facebook{
  background-position-y:-1090px
}

.footer-nav__container .social_link span.footer_vk{
  background-position-y:-1119px
}

.footer-nav__container .social_link span.footer_im{
  background-position-y:-1206px
}