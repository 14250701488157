@font-face{
  font-family:"Aeroport";

  src:local("AeroportBold"),url(/var/resources/static/fonts/Aeroport-bold.eot) format("embedded-opentype"),url(/var/resources/static/fonts/Aeroport-bold.woff2) format("woff2"),url(/var/resources/static/fonts/Aeroport-bold.woff) format("woff"),url(/var/resources/static/fonts/Aeroport-bold.ttf) format("truetype");

  font-weight:700;

  font-style:normal;

  font-display:swap
}

@font-face{
  font-family:"Aeroport";

  src:local("AeroportBoldItalic"),url(/var/resources/static/fonts/Aeroport-bold-italic.eot) format("embedded-opentype"),url(/var/resources/static/fonts/Aeroport-bold-italic.woff2) format("woff2"),url(/var/resources/static/fonts/Aeroport-bold-italic.woff) format("woff"),url(/var/resources/static/fonts/Aeroport-bold-italic.ttf) format("truetype");

  font-weight:700;

  font-style:italic;

  font-display:swap
}

@font-face{
  font-family:"Aeroport";

  src:local("AeroportLight"),url(/var/resources/static/fonts/Aeroport-light.eot) format("embedded-opentype"),url(/var/resources/static/fonts/Aeroport-light.woff2) format("woff2"),url(/var/resources/static/fonts/Aeroport-light.woff) format("woff"),url(/var/resources/static/fonts/Aeroport-light.ttf) format("truetype");

  font-weight:300;

  font-style:normal;

  font-display:swap
}

@font-face{
  font-family:"Aeroport";

  src:local("AeroportLightItalic"),url(/var/resources/static/fonts/Aeroport-light-italic.eot) format("embedded-opentype"),url(/var/resources/static/fonts/Aeroport-light-italic.woff2) format("woff2"),url(/var/resources/static/fonts/Aeroport-light-italic.woff) format("woff"),url(/var/resources/static/fonts/Aeroport-light-italic.ttf) format("truetype");

  font-weight:300;

  font-style:italic;

  font-display:swap
}

@font-face{
  font-family:"Aeroport";

  src:local("AeroportMedium"),url(/var/resources/static/fonts/Aeroport-medium.eot) format("embedded-opentype"),url(/var/resources/static/fonts/Aeroport-medium.woff2) format("woff2"),url(/var/resources/static/fonts/Aeroport-medium.woff) format("woff"),url(/var/resources/static/fonts/Aeroport-medium.ttf) format("truetype");

  font-weight:500;

  font-style:normal;

  font-display:swap
}

@font-face{
  font-family:"Aeroport";

  src:local("AeroportMediumItalic"),url(/var/resources/static/fonts/Aeroport-medium-italic.eot) format("embedded-opentype"),url(/var/resources/static/fonts/Aeroport-medium-italic.woff2) format("woff2"),url(/var/resources/static/fonts/Aeroport-medium-italic.woff) format("woff"),url(/var/resources/static/fonts/Aeroport-medium-italic.ttf) format("truetype");

  font-weight:500;

  font-style:italic;

  font-display:swap
}

@font-face{
  font-family:"Aeroport";

  src:local("Aeroport"),url(/var/resources/static/fonts/Aeroport-regular.eot) format("embedded-opentype"),url(/var/resources/static/fonts/Aeroport-regular.woff2) format("woff2"),url(/var/resources/static/fonts/Aeroport-regular.woff) format("woff"),url(/var/resources/static/fonts/Aeroport-regular.ttf) format("truetype");

  font-weight:400;

  font-style:normal;

  font-display:swap
}

@font-face{
  font-family:"Aeroport";

  src:local("AeroportItalic"),url(/var/resources/static/fonts/Aeroport-italic.eot) format("embedded-opentype"),url(/var/resources/static/fonts/Aeroport-italic.woff2) format("woff2"),url(/var/resources/static/fonts/Aeroport-italic.woff) format("woff"),url(/var/resources/static/fonts/Aeroport-italic.ttf) format("truetype");

  font-weight:400;

  font-style:italic;

  font-display:swap
}

@font-face{
  font-family:"AeroportMono";

  src:local("AeroportMono"),url(/var/resources/static/fonts/Aeroport-monospaced.eot) format("embedded-opentype"),url(/var/resources/static/fonts/Aeroport-monospaced.woff2) format("woff2"),url(/var/resources/static/fonts/Aeroport-monospaced.woff) format("woff"),url(/var/resources/static/fonts/Aeroport-monospaced.ttf) format("truetype");

  font-style:normal;

  font-weight:400;

  font-display:swap
}

@font-face{
  font-family:"Open Sans";

  src:local("Open Sans Light"),url(/var/resources/static/fonts/opensanslight.woff2) format("woff2"),url(/var/resources/static/fonts/opensanslight.woff) format("woff");

  font-style:normal;

  font-weight:300;

  font-display:swap
}

@font-face{
  font-family:"Open Sans";

  src:local("Open Sans"),url(/var/resources/static/fonts/opensans.woff2) format("woff2"),url(/var/resources/static/fonts/opensans.woff) format("woff");

  font-style:normal;

  font-weight:400;

  font-display:swap
}

@font-face{
  font-family:"Open Sans";

  src:local("Open Sans Bold"),url(/var/resources/static/fonts/opensansbold.woff2) format("woff2"),url(/var/resources/static/fonts/opensansbold.woff) format("woff");

  font-style:normal;

  font-weight:700;

  font-display:swap
}

@font-face{
  font-family:"RUBSN";

  src:url(/var/resources/static/fonts/RUBSN.woff2) format("woff2"),url(/var/resources/static/fonts/RUBSN.woff) format("woff");

  font-weight:normal;

  font-style:normal;

  font-display:auto
}