.error_notification_container[data-v-8f5645ee]{
  position:fixed;
  top:0;
  left:50%;
  padding:5px 10px 5px 10px;
  border-bottom-left-radius:10px;
  border-bottom-right-radius:10px;
  font-size:14px;
  line-height:21px;
  -webkit-hyphens:none;
          hyphens:none;
  color:#fff;
  text-align:center;
  background:red;
  transition:all .3s ease-in-out;
  transform-origin:left top;
  transform:scaleY(0) translateX(-50%);
  z-index:9999
}
.error_notification_container_animate[data-v-8f5645ee]{
  transform:scaleY(1) translateX(-50%)
}